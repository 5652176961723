/* You can add global styles to this file, and also import other style files */
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0 !important;
}

.toast-top-center {
  width: 100px !important;
}

// breakpoints
$smbr: max-width 600px;
$mdbr: 768px;
$lgbr: 992px;
$xlbr: 1200px;

@mixin xs {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 400px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}
// Colors

// Fonts

// Text
